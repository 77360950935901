<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">List Of Account Number</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="no"
          dense
          disable-pagination
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat v-if="!detail">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-row>
                    <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                      <v-subheader>Account Number</v-subheader>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" class="py-0">
                      <v-text-field
                        outlined
                        flat
                        dense
                        background-color="white"
                        v-model="accountNumber"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" lg="2" class="py-0">
                      <v-btn color="success" @click="add">Add</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="error" icon @click.stop="openDialog(item.id)" v-if="!detail">
              <v-icon>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-confrim-account-number
      :dialog="dialogConfrim"
      :id="accountNumberId"
      @deleteAccountNumber="deleteItem"
      @close="closeConfirm"
    ></dialog-confrim-account-number>
  </v-dialog>
</template>

<script>
import DialogConfrimAccountNumber from "./DialogConfrimAccountNumber.vue";

export default {
  name: "dialog-view-account-number",
  props: {
    dialog: Boolean,
    items: Array,
    id: Number,
    detail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogConfrimAccountNumber,
  },
  data() {
    return {
      accountNumber: "",
      dialogConfrim: false,
      accountNumberId: null,
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Account Number",
          value: "number",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    openDialog(id) {
      this.accountNumberId = id;
      this.dialogConfrim = true;
    },
    closeConfirm() {
      this, (this.dialogConfrim = false);
    },
    async deleteItem(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bank/deleteAccountNumber", id)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async add() {
      this.$store.commit("SET_LOADER", true);
      let body = [];
      body.push({
        bankId: this.id,
        number: this.accountNumber,
      });
      await this.$store
        .dispatch("bank/createAccountNumber", body)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
