<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Confrim</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="red--text text-uppercase font-weight-bold">Peringatan !</div>
            <div>
              Account Number yang dihapus akan permanen terhapus.
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">
          No
        </v-btn>
        <v-btn color="error" @click="confirm">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-confirm-account-number",
  props: {
    dialog: Boolean,
    id: Number,
  },
  methods: {
    confirm() {
      this.$emit("deleteAccountNumber", this.id);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
